import { Component } from 'react'
import { connect } from "react-redux"
import { userLoginOIDC } from "../../actions"
import { withRouter } from 'react-router'
import './Loading.css';

class Callback extends Component {
    componentWillMount() {
      const search = this.props.location.search;
      const code = new URLSearchParams(search).get('code');
            
      this.props.userLoginOIDC(code, () => this.props.history.push('/'));
    }
    render() {
      return (<div className="loading_parent" style={{display: 'block'}}>
              <div className="la-line-scale la-dark la-2x main-loading">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
              </div>
              <div className="load-overlay"></div>
          </div>);
    }
    
}


export default withRouter(connect(null, { userLoginOIDC })(Callback))  
 