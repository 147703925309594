import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Dropdown from 'react-bootstrap/Dropdown'
import DatePicker from "react-datepicker" 
import "react-datepicker/dist/react-datepicker.css"

export default class ReimbursementSearch extends Component {

    constructor(props) {
        super(props);
        
        const default_search = {'name' : 'FDA', 'flag' : 'us', 'drug' : 'Libtayo'}
        //const default_search = {'name' : 'ALL', 'flag' : 'us', 'drug' : 'Keytruda'}
        this.state = { drug_name : default_search.drug ,
            country: default_search.name,
            flag: default_search.flag,
            date_start : null,
            date_end : null};
        this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount() {
        this.props.onSearch(this.state.drug_name, this.state.country, this.state.flag);
    }
    
    handleClick(e){ 
        //console.log(e.target.attributes.getNamedItem('data-state').value);
        this.setState({[e.target.attributes.getNamedItem('data-state').value]: e.target.text});
        if(e.target.attributes.getNamedItem('data-flag')){
            this.setState({flag: e.target.attributes.getNamedItem('data-flag').value});
        }
    }
    handleChange = (value,state_name) => {
        //const formatted_date = value.getFullYear() + "-" + ("0" + (value.getMonth() + 1)).slice(-2)        
        this.setState({
            [state_name]: value,
        });
      };

    render() {
        return (
            <div className="box-list">
                <div className="box box-md mr-3">
                <table className="w-100">
                    <tbody><tr>
                        <td><strong>Drugs</strong></td>
                        <td>
                        <Dropdown >
                        <Dropdown.Toggle variant="success" id="dropdown-drug" className="btn btn-light btn-custom dropdown-toggle btn-block"  >
                            <span className="fa-pull-left">{this.state.drug_name}</span>  
                            <span className="fa-pull-right text-black"><FontAwesomeIcon icon="angle-down"/></span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu  className="dropdown-cs" >
                            {this.props.DrugName.map((variant, idx) => (
                            <Dropdown.Item key={idx} data-state="drug_name" onClick={this.handleClick} >{variant}</Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                        </Dropdown>
                        </td>
                    </tr>
                    </tbody></table>
                </div>
                <div className="box box-md mr-3">
                    <table className="w-100">
                        <tbody><tr>
                            <td><strong>COUNTRY</strong></td>
                            <td>
                            <Dropdown >
                            <Dropdown.Toggle variant="success" id="dropdown-country" className="btn btn-light btn-custom dropdown-toggle btn-block"  >
                                <span className="fa-pull-left">{this.state.country} </span>  
                                <span className="fa-pull-right text-black"><FontAwesomeIcon icon="angle-down"/></span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu  className="dropdown-cs" >
                                {this.props.CountryArr.map((variant, idx) => (
                                <Dropdown.Item key={idx} data-state="country" data-flag={variant.flag} onClick={this.handleClick}>{variant.name}</Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                            </Dropdown>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div className="box box-md mr-3 box-md-date">
                    <table className="w-100">
                        <tbody><tr>
                            <td><strong>DATE</strong></td>
                            <td>
                            <DatePicker
                                selected={this.state.date_start}
                                onChange={(e) => this.handleChange(e,"date_start")}
                                dateFormat="MM/yyyy"
                                showMonthYearPicker
                                className="form-control input-cs"
                                placeholderText="From"
                                calendarClassName="custom-datepicker"
                            />
                            </td>
                            <td> / </td>
                            <td>
                            <DatePicker
                                selected={this.state.date_end}
                                onChange={(e) => this.handleChange(e,"date_end")}
                                dateFormat="MM/yyyy"
                                showMonthYearPicker
                                className="form-control input-cs"
                                placeholderText="To"
                                calendarClassName="custom-datepicker"
                            />
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div className="box box-sm mr-3">
                <button className="btn btn-dark btn-dark-blue btn-block" type="button" onClick={() => this.props.onSearch(this.state.drug_name, this.state.country,this.state.flag,this.state.date_start,this.state.date_end)}><FontAwesomeIcon icon="search" style={{marginRight: 5}}/> Start Search</button>
                </div>
                {/* <div className="box box-sm main-overlay">
                <button className="btn btn-dark btn-dark-blue btn-block" type="button" onClick={() => this.props.downloadFile(this.state.drug_name, this.state.country)}><FontAwesomeIcon  icon="long-arrow-alt-down" style={{marginRight: 5}}/> DOWNLOAD FILE</button>
                { (this.props.wait_download_excel ) && (
                <div className="overlay"></div>
                )}
                </div> */}
            </div>
        )
    }
}
