import React, { Component } from 'react'
import axios from "axios";
import Header from "../layout/Header"
import Footer from "../layout/Footer"
import { connect } from "react-redux"
import TopMenu from "../layout/TopMenu"
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { far } from '@fortawesome/free-regular-svg-icons'
import { HtaDocuments_Fetch } from "../../actions"
import { Link  } from "react-router-dom" 
import Button from 'react-bootstrap/Button'
import LeftMenu from "../layout/LeftMenu"
import { isAdmin, SaveLog } from '../../utils';
import {withAuth} from '../../actions/header_authorization';
library.add(far)

class HtaDocumentShow extends Component {

    constructor(props) {
        super(props);
        this.state = { 
            log_reference :new Date().getTime()
        };
    }

    componentWillMount() {
        SaveLog(this.state.log_reference, "Regulatory/HTA documents", true)
        this.props.HtaDocuments_Fetch()
    }
    
    componentWillUnmount(){   
        SaveLog(this.state.log_reference, "",  false)        
    }

    
    /* loadpdf = () => {
        axios({
            //url: 'http://localhost:3000/uploads/Transparence/257/TRS_lien_pdf_file.pdf',
            url: 'http://127.0.0.1:5000/test_get_pdf?id=1582&type=fda',
            method: 'GET',
            responseType: 'blob',
           
          }).then((response) => { 
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'file.pdf');
            document.body.appendChild(link);
            link.click();
          });
    } */
    DownloadFile2(path, id, filename ){
        console.log(path +" ----- " + id +" ----- "+ filename);
        const typeFile = path.split('.').pop();  
        
        axios.post(`${process.env.REACT_APP_API_LARAVEL}/api/hta_document/get_file`, {id : id}, { 				
            headers: withAuth(),
            responseType: 'blob',
         })
        .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename+"."+typeFile);
            document.body.appendChild(link);
            link.click();
        });
        
       
    }

    DownloadFile(url, filename ){
        SaveLog(this.state.log_reference, "Click Document : " + filename, false)
        const typeFile = url.split('.').pop();  

        axios.get(`${process.env.REACT_APP_API_LARAVEL}/api/file?path=${url}&type=response`, { headers : withAuth(), responseType: 'arraybuffer'})
        .then(res => {  
            if(res.headers['content-length'] == 51){
                
                alert("Error, The specified blob does not exist");
                return false;
            }
            const contentType = res.headers['content-type'];                    
            const blob = new Blob([res.data], {type: contentType});
            const objectFile = window.URL.createObjectURL(blob);    
           
            const link = document.createElement('a');
            link.href = objectFile;
            link.setAttribute('download', filename+"."+typeFile);
            document.body.appendChild(link);
            link.click();
            
        })
        .catch(error  => {            
            console.log(error);
        });  
        
        // axios({            
        //     url: url,
        //     method: 'GET',
        //     responseType: 'blob', // important
        //   }).then((response) => {
        //     const url = window.URL.createObjectURL(new Blob([response.data]));
        //     const link = document.createElement('a');
        //     link.href = url;
        //     link.setAttribute('download', filename+"."+typeFile);
        //     document.body.appendChild(link);
        //     link.click();
        //   }); 
    }

    

    render() {
        
        console.log(this.props.HtaDocuments)
        return (
            <div>
                <Header />
                <div className="container-fluid">
                    <div className="row">
                        <div className="box-tab-fixed w-100">
                            <TopMenu />
                        </div>
                    </div>

                    <div className="row row-table-content">
                        <div className="box-table w-100">
                            <div className="box-content">
                                <LeftMenu />
                                <div className="child-box-right">
                                { isAdmin() && (
                                <Link to="/hta_document/list"><Button variant="secondary" style={{ 'marginBottom' : '8px', 'float' : 'right' }}> Management </Button></Link>
                                )}
                                    <div className="bg-light-gray">
                                        <div className="row">                                      
                            
                                        { this.props.HtaDocuments.length > 0 && this.props.HtaDocuments.map(document => (
                                            
                                            <div className="col-12 col-sm-6 col-md-6" key={document.id}>
                                                <div className="box-list-col w-100 align-self-center">
                                                    <div className="col-chlid"><FontAwesomeIcon style={{ 'fontSize': '5em', 'color': '#e40b7e' }} icon={['far', 'file-alt']} /></div>
                                                    <div className="col-chlid w-100">
                                                        <div className="wrap-col-content">
                                                            <div className="col-content">
                                                                <div>{document.name}</div>
                                                                <div>{document.date}</div>
                                                            </div>
                                                            <div className="col-tab-download" >
                                                                <div onClick={() => this.DownloadFile(`${document.path}`, document.name + " " + document.date)}>
                                                                <div className="box-arrow" ><FontAwesomeIcon icon="long-arrow-alt-down" /> </div>
                                                                DOWNLOAD
                                                                </div>
                                                                {/* <a href={`${process.env.REACT_APP_API_LARAVEL}${document.path}`} download="sss" onClick={() => SaveLog(this.state.log_reference, "Click Document : " + document.name + " " + document.date, false)}  className="a-box-arrow" >                                                                
                                                                <div className="box-arrow" ><FontAwesomeIcon icon="long-arrow-alt-down" /> </div>
                                                                DOWNLOAD
                                                                </a> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        ))} 
                                    
                                        
                                            
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
                <Footer />
            </div>
        )
    }
}


function mapStateToProps({ HtaDocuments }) {
    return { HtaDocuments };
}


export default connect(mapStateToProps, { HtaDocuments_Fetch })(HtaDocumentShow);