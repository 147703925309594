import axios from "axios";
import {
	USER_LOGIN, USER_LOGOUT
} from "./types";
import { reset } from 'redux-form';
import {withAuth} from './header_authorization';


export const validateEmail = email => {
    email = email.email
    if(email.split('@').length > 1 && ["iqvia.com","quintiles.com"].includes(email.split('@')[1])){
        axios.get(`${process.env.REACT_APP_API_LARAVEL}/api/auth/get_authorize_endpoint`).then(response  => {
            window.location.href = response.data.endpoint
       })
      } else {
        window.location.href = '/login?email='+email
      }
};

export const userLogin = newUser => {
    //console.log(newUser);
	return dispatch => {
        dispatch({ type: USER_LOGIN ,loggingIn : true, msg:"", type_alert:"", redirect: false });
        axios.post(`${process.env.REACT_APP_API_LARAVEL}/api/auth/login`, newUser, {
             headers: { 'Content-Type': 'application/json' }
        }).then(response  => {
            //console.log(newUser);
            //console.log(response);
            localStorage.setItem('token', response.data.token);
            //return response.data.token;
			dispatch(reset('loginForm')); 
			dispatch({ type: USER_LOGIN , msg:"", type_alert:"", redirect: true  });
        })
        .catch(err => {
            let msg = "Username or password is incorrect.";
            console.log(err.response);
            if(err.response.error !== undefined){
                msg = err.response.error.join(" ");
            }
            dispatch(reset('loginForm')); 
            dispatch({ type: USER_LOGIN ,loggingIn : false, msg:msg, type_alert:"danger", redirect: false  });
        });
	};
};

export function userLoginOIDC(code, onSuccess) {
    return dispatch => {      
        axios.post(`${process.env.REACT_APP_API_LARAVEL}/api/auth/login_oidc/${code}`, null, {
            headers: withAuth({ 'Content-Type': 'application/json' })
        }).then(response  => {           
            dispatch(reset('loginForm'));  
            localStorage.setItem('token', response.data.token);
			dispatch({ type: USER_LOGIN , msg:"", type_alert:"", redirect: true  });  
            onSuccess();  
        })
        .catch(err => {
            let msg = "Username or password is incorrect.";
            alert(msg);
            dispatch({ type: USER_LOGIN ,loggingIn : false, msg:msg, type_alert:"danger", redirect: false  });       
        });
    }
}

export function userLogout() {
    return dispatch => {      
        axios.post(`${process.env.REACT_APP_API_LARAVEL}/api/auth/logout`, null, {
            headers: withAuth({ 'Content-Type': 'application/json' })
        }).then(response  => {            
            dispatch({ type: USER_LOGOUT })          
        })
        .catch(err => {
            console.log(err)            
        });
        localStorage.removeItem('token')
    }
}
/* 
export function refreshToken(dispatch) {
    return dispatch => { 
        console.log('error refreshing token 9999999999');       
        dispatch({ type: REFRESHING_TOKEN });
        axios.post(`${process.env.REACT_APP_API_LARAVEL}/api/refresh_token`,{
             headers: withAuth({ 'Content-Type': 'application/json' })
        }).then(response  => {
            console.log(response.data);
            localStorage.setItem('token', response.data.data);
			dispatch({ type: DONE_REFRESHING_TOKEN });
        })
        .catch(err => {
            console.log('error refreshing token', err);          
            dispatch({ type: DONE_REFRESHING_TOKEN });
        });
    };
    
 
    
} */