import React, { Component } from 'react'
import { Redirect } from "react-router-dom";
import Footer from "../layout/Footer"; 
import { connect } from "react-redux";
import { validateEmail } from "../../actions";
import { reduxForm, Field } from "redux-form";
import {FormField } from "../FormField"; 
import { isLogin } from '../../utils';
import axios from "axios";

class PreLogin extends Component {
    constructor(props) {
        super(props); 
        this.state = { 'Redirect' : false , log_reference :new Date().getTime() };
    }
    componentWillMount(){
        if(isLogin()){     
            this.setState({'Redirect' : true})            
        }
    }
    componentDidMount(){
        const postData = {
            'reference' : this.state.log_reference,
            'url' : window.location.href,
            'detail' : "PreLogin",
            'open' : true       
        }
        axios.post(`${process.env.REACT_APP_API_LARAVEL}/api/log_guest?guest=1`, postData ,{
            headers: { 'Content-Type': 'application/json' }
        })        
    }
    componentWillUnmount(){   
        const postData = {
            'reference' : this.state.log_reference,
            'url' : window.location.href,
            'detail' : "",
            'open' : false       
        }
        axios.post(`${process.env.REACT_APP_API_LARAVEL}/api/log_guest?guest=1`, postData ,{
            headers: { 'Content-Type': 'application/json' }
        })          
       
    }
    componentWillUpdate(){ 
        if(isLogin()){     
            this.setState({'Redirect' : true})            
        }
    }
    

    render() {
        if(this.state.Redirect){
            return (<Redirect to={'/'}/>)
        }       
        const { validateEmail, handleSubmit } = this.props;
        
        return (
            <div className="login">
                <div className="container-fluid">
                    <div className="header-text text-center">PD-(L)1 Regulatory, HTA and Reimbursement Landscape</div>
                    <div className="row wrapper">
                        <form className="form-signin main-overlay" onSubmit={handleSubmit(validateEmail)}>    
                            <h1 className="h3 mb-3 font-weight-bold text-center">Sign In</h1>
                            <Field  
                                name="email" 
                                type="email" 
                                component={FormField} 
                                label="Email address" 
                                required={true} 
                                placeholder="Enter email" 
                            />
                               
                             <button className="btn btn-md btn-primary btn-block mt-3" type="submit">Submit</button>
                            
                        </form>
                    </div>
                    
                </div>
                <Footer />
            </div>
        )
    }
}


function validate(values) {
	const errors = {};	
    if (!values.email) {
        errors.email = 'Required'
    }
	return errors;
}
 

PreLogin = reduxForm({ validate, form: "PreloginForm" })(PreLogin);

function mapStateToProps() {
    
	return { };
}


export default connect(mapStateToProps, { validateEmail })(PreLogin);