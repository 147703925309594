import { Component } from 'react'
import { connect } from "react-redux"
import { userLogout } from "../../actions"
import { withRouter } from 'react-router'
import { SaveLog } from '../../utils';
class Logout extends Component {
    componentWillMount() {
        SaveLog(new Date().getTime(), "Logout", true)
        this.props.userLogout()
        this.props.history.push('/prelogin')
    }
    render() {
        return null;
    }
    
}


export default withRouter(connect(null, { userLogout })(Logout))  
 